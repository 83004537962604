import { useEffect } from "react";
import "../styles.css";
import logo from "../Pictures/noWhiteLogo.png";

export default function NewNavBar() {
  useEffect(() => {
    let scrollpos = window.scrollY;
    const header = document.querySelector("nav");
    const header_height = header.offsetHeight;

    const add_class_on_scroll = () => header.classList.add("grey");
    const remove_class_on_scroll = () => header.classList.remove("grey");

    window.addEventListener("scroll", function () {
      scrollpos = window.scrollY;

      if (scrollpos >= header_height) {
        add_class_on_scroll();
      } else {
        remove_class_on_scroll();
      }
    });
  }, []);

  return (
    <div className="page" id="navbar">
      <nav className="page__menu menu">
        <img className="logo" src={logo} />
        <div>
          <ul className="menu__list r-list">
            <li className="menu__group">
              <a href="#root" className="menu__link r-link text-underlined">
                Home
              </a>
            </li>
            <li className="menu__group">
              <a href="#AboutUs" className="menu__link r-link text-underlined">
                About Us
              </a>
            </li>
            <li className="menu__group text-underlined"></li>
            <li className="menu__group">
              <a href="#Services" className="menu__link r-link text-underlined">
                Services
              </a>
            </li>

            <li className="menu__group">
              <a
                href="#ContactMe"
                className="menu__link r-link text-underlined"
              >
                Contact
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
}
