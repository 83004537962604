// import './Intro.css'
import "../styles.css";
import Projects from "./Projects";
import logo from "../Pictures/noWhiteLogo.png";

export default function Intro() {
  return (
    <div id="AboutUs">
      <h1 style={{ textAlign: "center" }}>About Us</h1>
      <div class="hero" id="AboutMe">
        <div class="content">
          {/* <Projects/> */}
          <img class="stagger1" src={logo} alt="Logo" />
        </div>
        <div class="aboutContent">
          <p>
            Our mission at Pine Roofing is to provide the best quality work and
            products that accommodate your aesthetic, and project goals, all
            while earning your trust along the way.
          </p>
          <p>
            We are licensed professionals with over 25 years experience
            installing and servicing roofs. Pine Roofing will tackle any
            challenge, big or small, and always guarantee customer satisfaction.
            Our licence number is CCC1328152
          </p>
        </div>
      </div>
    </div>
  );
}
