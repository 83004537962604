import Home from "./Components/Home";
import NewNavBar from "./Components/NewNavBar";
import Projects from "./Components/Projects";
import ContactUs from "./Components/ContactUs";
import AboutUs from "./Components/AboutUs";
import Footer from "./Components/Footer";
import PotentialHome from "./Components/PotentialHome";
import Transition from "./Components/Transition";
import Services from "./Components/Services";

import "./styles.css";

function App() {
  return (
    <div>
      <NewNavBar />
      <PotentialHome />
      <Services />
      <AboutUs />
      <Transition />
      <ContactUs /> 
      <Footer />
    </div>
  );
}

export default App;
