import emailjs from "emailjs-com";
import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import "../styles.css";

export default function ContactUs() {
  const initialForm = {
    user_name: "",
    user_email: "",
    message: "",
  };

  const [form, setForm] = useState(initialForm);
  const [submit, setSubmit] = useState(false);

  const Submit = (event) => {
    event.preventDefault();
    setSubmit(true);
    setForm(initialForm);
    const templateId = "template_umeh0di";
    emailjs
      .send("service_a2pn19k", templateId, form, "user_BKdUZwviwywutz1eCYApP")
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          console.log(form);
        },
        (err) => {
          console.log("FAILED...", err);
        }
      );
  };
  // EVERYTHING TO DO WITH YUP VALIDATION ------------------------

  // State for the error messages
  const [errors, setErrors] = useState({
    user_name: "",
    user_email: "",
    message: "",
  });

  const [buttonDisabled, setButtonDisabled] = useState(true);

  const formSchema = Yup.object().shape({
    user_name: Yup.string().required("Must include Name"),
    user_email: Yup.string()
      .email("Must be a valid email address.")
      .required("Email is Required"),
    message: Yup.string()
    .required("Must include a Message"),
  });

  useEffect(() => {
    /* We pass the entire state into the entire schema, no need to use reach here. 
        We want to make sure it is all valid before we allow a user to submit
        isValid comes from Yup directly */
    formSchema.isValid(form).then((valid) => {
      setButtonDisabled(!valid);
    });
  }, [form]);

  const validateChange = (e) => {
    Yup.reach(formSchema, e.target.name)
      .validate(e.target.value)
      .then((valid) => {
        e.preventDefault();
        setErrors({
          ...errors,
          [e.target.name]: "",
        });
      })
      .catch((err) => {
        setErrors({
          ...errors,
          [e.target.name]: err.errors[0],
        });
      });
  };

  const Changing = (e) => {
    e.persist();
    const newFormData = {
      ...form,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    };
    validateChange(e);
    setForm(newFormData);
  };

  // EVERYTHING TO DO WITH YUP VALIDATION ------------------------

  return (
    <div class="contactWrap">
      <div className="contactTitle">
        <h1 class="contactH1">
          Like what you see? <span> Get a Free Quote!</span>
        </h1>
        {submit === true ? (
          <div>
            <p class="Email">Thank you, we will respond as soon as possible!</p>
          </div>
        ) : (
          <div />
        )}
      </div>
      <div id="ContactMe" class="ContactForm">
        <form onSubmit={Submit}>
          <div class="partA">
            <p class="contactP">Name:</p>
            <input
              name="user_name"
              onChange={Changing}
              value={form.user_name}
              placeholder="Name"
            />
            {errors.user_name.length > 0 ? (
              <p className="error">{errors.user_name}</p>
            ) : null}

            <p class="contactP">Email:</p>
            <input
              name="user_email"
              type="email"
              placeholder="Email"
              onChange={Changing}
              value={form.user_email}
            />
            {errors.user_email.length > 0 && (
              <p className="error">{errors.user_email}</p>
            )}
          </div>

          <div class="partB">
            <p class="contactP">Message:</p>
            <textarea
              name="message"
              onChange={Changing}
              placeholder="Message "
              value={form.message}
              style={{ height: "200px" }}
            />
            {errors.message.length > 5 && (
              <p className="error">{errors.message}</p>
            )}
          </div>
          <button type="submit" disabled={buttonDisabled}>
            {" "}
            Send Email!{" "}
          </button>
        </form>
        <div class="Hours">
          <h2>Contact Information</h2>
          <h3>pineroofingservices@gmail.com</h3>
          <h4>2348 W 8th Court Hialeah, Fl 33010</h4>
          <h1>305-319-3004</h1>
        </div>
      </div>
    </div>
  );
}
