import ygreen from "../Pictures/ygreenR2.png";
import synchrony from "../Pictures/synchronyR.png";

export default function Transition() {
  return (
    <div className="Transition">
      <h1>Ask About Financing!</h1>
      <p>
        100% financing is now available! Start getting service done with no down
        payment!
      </p>
      <p>A statisfied customer is our best business strategy!</p>
      <div className="transitionImgs">
        <img src={ygreen} />
        <img className="synchrony" src={synchrony} />
      </div>

      {/* <p>
        We know that our business's success depends on just how strong we are at
        fixing your roofing problem in a timely and cost-effective manner. We
        thank you for the support and will continue prioritizing our customer
        service to provide you the service you deserve.
      </p> */}
    </div>
  );
}
