import install from "../Pictures/photo3.jpg";
import reroof from "../Pictures/photo4.jpeg";
import repair from "../Pictures/roofingman.jpeg";

export default function Services() {
  return (
    <div id="Services" className="ServiceSection">
      <h1 className="serviceTitle">Services</h1>
      <div class="serviceContainer">
        <div className="pic">
          <img src={install} alt="" />
        </div>
        <div class="serviceContent">
          <h1>Residential & Commericial Roofing Installation</h1>
          {/* <p>A long thought out description of the service</p> */}
          <p>
            Your roof is the first line of defense against the elements when it
            comes to your property. Pine Roofing specializes in{" "}
            <span>Metal, Tile, Shingle, and Flat roof installation</span>! We
            use quality materials in order to ensure your satisfaction.
          </p>
        </div>
      </div>

      {/* //Desktop */}
      <div class="serviceContainer desktop">
        <div class="serviceContent">
          <h1>Re-Roof</h1>
          {/* <p>A long thought out description of the service</p> */}
          <p>
            A roof replacement is the most extensive process of roofing and
            carries with it a long-term solution to roof issues. Installing a
            new roof provides 20 years or more of life. This is usually the best
            long-term solution depending on the condition of the current roof.
            Replacement is neccesary when a roofing system is damaged beyond
            repair.
          </p>
        </div>
        <div className="pic">
          <img src={reroof} alt="" />
        </div>
      </div>

      {/* //Phone */}
      <div class="serviceContainer phone">
        <div className="pic">
          <img src={reroof} alt="" />
        </div>
        <div class="serviceContent">
          <h1>Re-Roof</h1>
          {/* <p>A long thought out description of the service</p> */}
          <p>
            A roof replacement is the most extensive process of roofing and
            carries with it a long-term solution to roof issues. Installing a
            new roof provides 20 years or more of life. This is usually the best
            long-term solution depending on the condition of the current roof.
            Replacement is neccesary when a roofing system is damaged beyond
            repair.
          </p>
        </div>
      </div>

      <div class="serviceContainer">
        <div className="pic">
          <img src={repair} alt="" />
        </div>
        <div class="serviceContent">
          <h1>Repair</h1>
          {/* <p>A long thought out description of the service</p> */}
          <p>
            Roof Repair is an immediate and temporary solution to an active
            leak. We refurbish all damages on the roofs' surfaces. This includes
            caulking, minor resurfacing and penetration damage. Regardless of
            size, contact us today to get a free quote!
          </p>
        </div>
      </div>
    </div>
  );
}
