import gutterInstallation from "../Pictures/gutterInstall.png";
// import "../styles.css";
import worker from "../Pictures/worker.jpg";
import roofer from "../Pictures/roofer.jpeg";
import roofingman from "../Pictures/roofingman.jpeg";
import photo1 from "../Pictures/photo1.jpg";
import photo2 from "../Pictures/photo2.jpg";
import photo3 from "../Pictures/photo3.jpg";
import photo5 from "../Pictures/photo5.JPG";
import photo6 from "../Pictures/photo6.JPG";

import Carousel from "react-bootstrap/Carousel";

export default function PotentialHome() {
  return (
    <div className="wrapper">
      <div className="content" id="Home">
        <div className="openingText">
          <h1>Pine Roofing</h1>
          <h3>Service done right</h3>
        </div>
        <a href="#ContactMe">
          <button className="contactBtn">Contact Us</button>
        </a>
      </div>
      <div>
        <Carousel
          fade
          class="background"
          indicators={false}
          controls={false}
          interval={3000}
        >
          <Carousel.Item>
            <img className="d-block w-100" src={photo2} alt="First slide" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src={photo3} alt="Second slide" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src={worker} alt="Third slide" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src={photo3} alt="Forth slide" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src={photo5} alt="Fifth slide" />
          </Carousel.Item>
          A
          <Carousel.Item>
            <img className="d-block w-100" src={photo6} alt="Sixth slide" />
          </Carousel.Item>
          {/* <Carousel.Item>
            <img className="d-block w-100" src={worker} alt="Seventh slide" />
          </Carousel.Item> */}
        </Carousel>
      </div>
    </div>
  );
}
